import {useRef, forwardRef, useImperativeHandle, useState} from "react";
import {Toolbar, Item as ToolbarItem} from "devextreme-react/toolbar";
import {Button} from "devextreme-react/button";
import {MultiView, Item as MultiViewItem} from "devextreme-react/multi-view";
import {Box, Item as BoxItem} from "devextreme-react/box";
import {LoadPanel} from "devextreme-react/load-panel";
import {useDispatch, useSelector} from "react-redux";

import {SalesHistoryForecast} from "./StrategyEditors/SalesHistoryForecast";
import {ManualForecast, ManualParentForecast} from "./StrategyEditors/ManualForecast";
import {ComparativeForecast} from "./StrategyEditors/ComparativeForecast";
import {ReplacementPartForecast} from "./StrategyEditors/ReplacementPartForecast";
import {useSalesEntityId} from "../../api/useAPI";
import {resetEditState} from "./editMenuSlice";
import {setActiveStrategy, resetActiveStrategy} from "./editMenuSlice";
import {setForecastPeriod} from "./editMenuSlice";
import {useMutateSalesPlanEntities, useMutateSalesPlanEntity} from "../../api/useSalesPlanEntity";

import '../../css/Detail.css'
import {TotalSalesForecast} from "./StrategyEditors/TotalSalesForecast";
import {ValidationErrorPopup} from "../../components/common/SavePopup";


const SaveButton = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const isEditState = useSelector(state => state.editMenu.isEditState);
    const {saveEditData} = useMutateSalesPlanEntity(props.salesEntityId);

    useImperativeHandle(ref, () => ({
        saveEditData: onClick,
        isEditState: () => isEditState,
    }));

    const onClick = () => {
        Object.keys(props.formRefs.current).forEach(k => {
            if (props.formRefs.current[k]) {
                props.formRefs.current[k].saveEditData();
            }
        });

        saveEditData();
        dispatch(resetEditState());
    };

    return (
        <Button
            icon={'save'}
            disabled={!isEditState}
            onClick={onClick}
            activeStateEnabled={false}
        />
    );
});

const SaveParentButton = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const isEditState = useSelector(state => state.editMenu.isEditState);
    const shares = useSelector(state => state.editMenu.changedShares);
    const parent = useSelector(state => state.editMenu.changedParent);
    const children = useSelector(state => state.editMenu.changedChildren);
    const {saveEditData} = useMutateSalesPlanEntities(props.salesEntityIds);

    const [isValidationErrorPopupVisible, setValidationErrorPopupVisibility] = useState(false);
    const [invalidMonths, setInvalidMonths] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');

    const monthPattern = /^\d{4}\/\d{2}$/

    useImperativeHandle(ref, () => ({
        saveEditData: onClick,
        isEditState: () => isEditState,
    }));



    const onClick = () => {
        const sharesSum = shares.reduce((acc, entity) => {
            Object.entries(entity).forEach(([key, value]) => {
                if (monthPattern.test(key) && typeof value === 'number') {
                    acc[key] = (acc[key] || 0) + value;
                }
            })
            return acc
        }, {});

        const monthsWithInvalidShares = Object.entries(sharesSum).filter(([key, value]) => Math.abs(1 - value) > 0.001).map(([key, value]) => key)

        const forecastSum = children.reduce((acc, entity) => {
            Object.entries(entity).forEach(([key, value]) => {
                if (monthPattern.test(key) && typeof value === 'number') {
                    acc[key] = (acc[key] || 0) + value;
                }
            })
            return acc
        }, {});

        const monthsWithInvalidForecast = Object.entries(forecastSum).filter(([month, value]) => value !== parent[month]).map(([month, value]) => month)

        if (monthsWithInvalidShares.length > 0) {
            setErrorMessage('The sum of the shares for each month must be equal to one.')
            setInvalidMonths(monthsWithInvalidShares);
            setValidationErrorPopupVisibility(true);
            return monthsWithInvalidShares
        }
        else if (monthsWithInvalidForecast.length > 0) {
            setErrorMessage('The sum of the children forecasts for each month must be equal to the parent forecast.')
            setInvalidMonths(monthsWithInvalidForecast);
            setValidationErrorPopupVisibility(true);
            return monthsWithInvalidForecast
        }
        else {
            Object.keys(props.formRefs.current).forEach(k => {
                if (props.formRefs.current[k]) {
                    props.formRefs.current[k].saveEditData();
                }
            });

            saveEditData();
            dispatch(resetEditState());
        }
    };

    return (
        <>
            <Button
                icon={'save'}
                disabled={!isEditState}
                onClick={onClick}
                activeStateEnabled={false}
            />
            <ValidationErrorPopup
                visible={isValidationErrorPopupVisible}
                onClose={() => setValidationErrorPopupVisibility(false)}
                text={errorMessage}
                invalidMonths={invalidMonths}
            />
        </>
    );
});


const RevertButton = props => {
    const dispatch = useDispatch();
    const isEditState = useSelector(state => state.editMenu.isEditState);

    const onClick = () => {
        Object.keys(props.formRefs.current).forEach(k => props.formRefs.current[k].cancelEdit());
        dispatch(resetEditState());
    };

    return (
        <Button
            icon={'revert'}
            disabled={!isEditState}
            onClick={onClick}
            activeStateEnabled={false}
        />
    );
};


const EditStrategies = forwardRef((props, ref) => {
    const formRefs = useRef({});
    const dispatch = useDispatch();

    const {data: salesEntityData, isSuccess} = useSalesEntityId(props.salesEntityData.id);

    const baseActiveStrategy = salesEntityData?.active_strategy;
    const activeStrategy = useSelector(state => state.editMenu.activeStrategy) || baseActiveStrategy;

    // Array of strategies to display inside details
    const strategies = [
        {
            value: 'sales_history',
            text: 'Previous sales',
            component: (
                <SalesHistoryForecast
                    ref={e => formRefs.current.sales_history = e}
                    dataSource={salesEntityData}
                />
            ),
        },
        {
            value: 'manual',
            text: 'Manual',
            component: (
                <ManualForecast
                    ref={e => formRefs.current.manual = e}
                    dataSource={salesEntityData}
                />
            ),
        },
        {
            value: 'inherit',
            text: 'Inherit',
            component: (
                <ComparativeForecast
                    ref={e => formRefs.current.inherit = e}
                    dataSource={salesEntityData}
                />
            ),
        },
        {
            value: 'replacement_part',
            text: 'Replacement part',
            component: (
                <ReplacementPartForecast
                    ref={e => formRefs.current.replacement_part = e}
                    dataSource={salesEntityData}
                />
            ),
        },
        {
            value: 'total_sales',
            text: 'Total sales',
            component: (
                <TotalSalesForecast
                    ref={e => formRefs.current.total_sales = e}
                    dataSource={salesEntityData}
                />
            ),
        }
    ];

    // Show load panel if data is not loaded yet
    if (!isSuccess) {
        return (
            <LoadPanel
                position={{
                    of: '#edit-strategy-popup',
                }}
                visible={true}
            />
        )
    }

    return (
        <Box height={'100%'} width={'100%'} direction={'col'}>
            <BoxItem key={'toolbar'} baseSize={50} shrink={0}>
                <Toolbar style={{paddingLeft: '10px', paddingRight: '10px'}}>
                    <ToolbarItem
                        location={'before'}
                        render={() => <div className={'toolbar-label'}><b>Use forecast type:</b></div>}
                    />
                    <ToolbarItem
                        widget={'dxSelectBox'}
                        location={'before'}
                        options={{
                            value: activeStrategy,
                            valueExpr: 'value',
                            displayExpr: 'text',
                            onValueChanged: ({value}) => (
                                value === baseActiveStrategy
                                    ? dispatch(resetActiveStrategy())
                                    : dispatch(setActiveStrategy(value))
                            ),
                            items: strategies.map(s => ({value: s.value, text: s.text})),
                        }}
                    />
                    <ToolbarItem location={'before'}>
                        <div className={'header-comment'}>
                            {
                                baseActiveStrategy !== activeStrategy && isSuccess
                                    ? `(was "${strategies.filter(s => s.value === baseActiveStrategy)[0]?.text}")`
                                    : ''
                            }
                        </div>
                    </ToolbarItem>
                    <ToolbarItem
                        location={'after'}
                    >
                        <SaveButton
                            ref={ref}
                            salesEntityId={props.salesEntityData.id}
                            formRefs={formRefs}
                        />
                    </ToolbarItem>
                    <ToolbarItem
                        location={'after'}
                    >
                        <RevertButton formRefs={formRefs}/>
                    </ToolbarItem>
                </Toolbar>
            </BoxItem>
            <BoxItem key={'strategies'} baseSize={500} ratio={1}>
                <MultiView
                    animationEnabled={false}
                    swipeEnabled={false}
                    selectedIndex={strategies.map(s => s.value).indexOf(activeStrategy)}
                    height={'100%'}
                >
                    {strategies.map((s, i) => (<MultiViewItem key={i}>{s.component}</MultiViewItem>))}
                </MultiView>
            </BoxItem>
        </Box>
    );
});

export const EditParentStrategies = forwardRef((props, ref) => {
    const formRefs = useRef({});
    const dispatch = useDispatch();


    const salesEntityData = props.salesEntityData?.children;
    console.log(`salesEntityData`, salesEntityData);

    return (
        <Box height={'100%'} width={'100%'} direction={'col'}>
            <BoxItem key={'toolbar'} baseSize={50} shrink={0}>
                <Toolbar style={{paddingLeft: '10px', paddingRight: '10px'}}>
                    <ToolbarItem
                        location={'before'}
                        render={() => <div className={'toolbar-label'}><b>Share forecast period:</b></div>}
                    />
                    <ToolbarItem
                        widget={'dxSelectBox'}
                        location={'before'}
                        options={{
                            value: 1,
                            valueExpr: 'value',
                            displayExpr: 'text',
                            onValueChanged: ({value}) => (
                                dispatch(setForecastPeriod(value))
                            ),
                            items: [
                                {
                                    value: 1,
                                    text: '1 month'
                                },
                                {
                                    value: 2,
                                    text: '2 months'
                                },
                                {
                                    value: 3,
                                    text: '3 months'
                                },
                                {
                                    value: 4,
                                    text: '4 months'
                                },
                                {
                                    value: 6,
                                    text: '6 months'
                                }
                            ],
                        }}
                    />
                    <ToolbarItem
                        location={'after'}
                    >
                        <SaveParentButton
                            ref={ref}
                            salesEntityIds={salesEntityData.map(x => x.id)}
                            formRefs={formRefs}
                        />
                    </ToolbarItem>
                    <ToolbarItem
                        location={'after'}
                    >
                        <RevertButton formRefs={formRefs}/>
                    </ToolbarItem>
                </Toolbar>
            </BoxItem>
            <BoxItem key={'strategies'} baseSize={500} ratio={1}>
                <div style={{height: '100%', overflow: 'auto'}}>
                    <ManualParentForecast
                        ref={e => formRefs.current.manual = e}
                        dataSource={salesEntityData}
                    />
                </div>
            </BoxItem>
        </Box>
);
});

export default EditStrategies;
