import {getAccessToken} from "./auth";

export const baseURL = process.env.REACT_APP_SERVER_URL;


class FetchError extends Error {
    constructor(response, message) {
        super(message);
        this.response = response;
    }
}


export function handleHTTPErrors(response) {
    if (!response.ok) {
        throw new FetchError(response);
    }

    return response;
}


async function makeAPIRequest(url, params) {
    const {headers, ...otherParams} = Object(params);

    const accessToken = await getAccessToken();
    const response = await fetch(url, {
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            ...(headers || {}),
        },
        ...otherParams,
    });

    return handleHTTPErrors(response).json();
}


export async function getSalesPlans() {
    return makeAPIRequest(`${baseURL}/sales_plans`);
}


export async function getSalesPlanById(salesPlanId) {
    return makeAPIRequest(`${baseURL}/sales_plans/${salesPlanId}`);
}

export async function getSalesPlanParentById(salesPlanId) {
    return makeAPIRequest(`${baseURL}/sales_plans_with_parents/${salesPlanId}`);
}


export async function getProductsByProductGroupId(productGroupId) {
    return makeAPIRequest(`${baseURL}/product_groups/${productGroupId}`);
}


export async function updateSalesPlanEntityById(salesPlanEntityId, updatedSalesPlanEntity) {
    return makeAPIRequest(`${baseURL}/sales_plan_entities/${salesPlanEntityId}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedSalesPlanEntity),
    })
}


export async function getSalesEntityById(salesEntityId) {
    return makeAPIRequest(`${baseURL}/sales_plan_entities/${salesEntityId}`);
}


export async function recalculateSalesPlanPredictios(salesPlanId) {
    return makeAPIRequest(`${baseURL}/sales_plans/${salesPlanId}/recalculate_forecasts`);
}


export async function exportSalesPlanToInventoryPlanner(salesPlanId) {
    const queryParams = new URLSearchParams({
        upload_to_gcs_flg: true,
    });

    return makeAPIRequest(
        `${baseURL}/export/inventory_planner/${salesPlanId}?` + queryParams,
        {method: 'POST'}
    );
}


export async function getInventoryPlannerAccountList() {
    return makeAPIRequest(`${baseURL}/inventory_planner/accounts`);
}

export async function getVariants(account_id, warehouse = null) {
    const filters = warehouse === null ? {} : {'warehouse_id': warehouse};
    const searchParams = new URLSearchParams(filters);

    return makeAPIRequest(`${baseURL}/inventory_planner/accounts/${account_id}/variants?` + searchParams);
}

export async function getWarehouses(account_id) {
    return makeAPIRequest(`${baseURL}/inventory_planner/accounts/${account_id}/warehouses`);
}
