import {useHistory} from "react-router-dom";
import {SelectBox} from "devextreme-react/select-box";
import {DropDownButton} from "devextreme-react/drop-down-button";
import {Item, Toolbar} from "devextreme-react/toolbar";
import {Chip} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";

import {useSalesPlansQuery} from "../../api/useAPI";
import {selectSalesPlan, setAsinLevel} from "./menuSlice";
import {deselectCompareSalesPlan} from "../SalesPlanGrid/salesPlanGridSlice";
import {useEffect, useState} from "react";

import '../../css/SalesPlanSelector.css';

const statusLevel = {
    NEW: 0,
    APPROVED: 10,
};


function SalesPlanSelectorItem(data) {
    const statusColorMap = {
        NEW: 'success',
        APPROVED: 'primary',
    };

    return (
        <div className={'SalesPlanItem'}>
            {data?.title}
            <Chip
                label={data?.status}
                size={'small'}
                color={statusColorMap[data?.status]}
                variant={'outlined'}
                className={'SalesPlanStatus'}
            />
        </div>
    );
}


function SalesPlanSelector(props) {
    const {data, refetch} = useSalesPlansQuery({notifyOnChangeProps: ['data']});
    const [salesPlansList, setSalesPlansList] = useState();

    useEffect(() => {
        if (data) {
            setSalesPlansList(
                [...data].sort((a, b) => (
                    a.status === b.status
                        ? (a.product_group.id === b.product_group.id
                            ? (b.from_year * 100 + b.from_month) - (a.from_year * 100 + a.from_month)
                            : a.title.localeCompare(b.title))
                        : statusLevel[a.status] - statusLevel[b.status]
                ))
            );
        }
    }, [data]);

    return (
        <SelectBox
            label={'Sales plan'}
            labelMode={'floating'}
            hint={'Choose sales plan'}
            width={300}
            dataSource={salesPlansList}
            valueExpr={'id'}
            displayExpr={item => item ? `${item?.title} (${item?.status})` : null}
            itemRender={SalesPlanSelectorItem}
            onOpened={refetch}
            onValueChanged={props.onSalesPlanChanged}
        />
    )
}


function Menu(props) {
    const {onLogout, onSalesPlanChanged} = props;
    const history = useHistory();
    const redirectTo = (path) => history.push(path);

    const dispatch = useDispatch();
    const asinLevel = useSelector((state) => state.menu.asinLevel);

    const menuButton = () => {
        return (
            <DropDownButton
                style={{margin: 5}}
                stylingMode={'text'}
                icon={'menu'}
                keyExpr={'text'}
                displayExpr={'text'}
                dataSource={[
                    {id: 1, text: 'Home', onClick: () => redirectTo('/')},
                    {id: 2, text: 'Forecast', onClick: () => redirectTo('/forecast')},
                    {id: 3, text: 'Logout', onClick: onLogout},
                ]}
                dropDownOptions={{
                    width: 100,
                }}
            />
        )
    }

    return (
        <Toolbar>
            <Item
                location={'before'}
                render={menuButton}
            />
            <Item
                location={'after'}
            >
                <SalesPlanSelector
                    onSalesPlanChanged={e => {
                        dispatch(selectSalesPlan(e.value));
                        dispatch(deselectCompareSalesPlan());
                        onSalesPlanChanged(e);
                    }}
                />
            </Item>
            <Item
                location={'after'}
            >
                <SelectBox
                    label={'Level'}
                    labelMode={'floating'}
                    hint={'Select role'}
                    width={200}
                    dataSource={['Child', 'Parent']}
                    value={asinLevel}
                    onValueChanged={e => {
                        dispatch(setAsinLevel(e.value));
                        if (props.onAsinLevelChanged) {
                            props.onAsinLevelChanged(e.value);
                        }
                    }}
                />
            </Item>
        </Toolbar>
    );
}

export default Menu;
