import {useState, forwardRef, Fragment, useImperativeHandle, useRef, useEffect} from "react";
import {useSelector} from "react-redux";
import {
    Chart,
    ArgumentAxis,
    CommonSeriesSettings,
    SeriesTemplate,
    Legend,
    Tooltip, ValueAxis
} from "devextreme-react/chart";
import {TagBox} from "devextreme-react/tag-box";
import {RangeSelector, Scale} from "devextreme-react/range-selector";
import {formatDate, formatNumber} from "devextreme/localization";
import compare from "just-compare";
import get from "just-safe-get";

import {useSalesPlanIdQuery} from "../../api/useAPI";
import '../../css/DataChart.css';


const DateSelector = forwardRef(({dataSource, onValueChanged, ...props}, ref) => {
    let [startValue, setStartValue] = useState(new Date('2020-01-01'));
    let [endValue, setEndValue] = useState(new Date('2022-12-01'));

    useEffect(() => {
        if (dataSource && dataSource?.sales_plan_entities) {
            const dates = [];

            dataSource.sales_plan_entities.forEach(entity => {
                entity.predictions.forEach(prediction => dates.push(new Date(prediction.year, prediction.month, 0)));
            });

            const maxDate = new Date(dates.reduce((max, date) => max > date ? max : date, null));
            const minDate = new Date(dates.reduce((min, date) => min < date ? min : date, null));

            if (minDate !== startValue) {
                setStartValue(minDate);
            }
            if (maxDate !== endValue) {
                setEndValue(maxDate);
            }
        }
    }, [dataSource]);

    return (
        <RangeSelector
            ref={ref}
            size={{height: 120}}
            margin={{top: 10}}
            theme={'generic.light'}
            onValueChanged={onValueChanged}
            {...props}
        >
            <Scale
                startValue={startValue}
                endValue={endValue}
                minorTickInterval={'month'}
            />
        </RangeSelector>
    )
});


function SliceSelector(props) {
    return (
        <TagBox
            dataSource={props.dataSource}
            valueExpr={'value'}
            displayExpr={'name'}
            showSelectionControls={true}
            placeholder={'Choose level of detail...'}
            width={300}
            onValueChanged={props.onValueChanged}
        />
    )
}


function prepareDataRow(salesPlanEntity, sliceType, fromYear = null, fromMonth = null, slicePrefix = undefined) {
    const preparedData = [];
    const common = {
        marketplace: salesPlanEntity.marketplace,
        region: salesPlanEntity.region,
        product_title: salesPlanEntity.product?.title,
        product_description: salesPlanEntity.product?.description,
    };

    if (Array.isArray(salesPlanEntity.predictions)) {
        salesPlanEntity.predictions.forEach(prediction => {
            const current = {
                ...common,
                date: new Date(prediction.year, prediction.month - 1, 1),
                year: prediction.year,
                month: `2000-${prediction.month.toString().padStart(2, '0')}-01`,
                forecast: prediction.forecast,
                isFact: prediction.year * 100 + prediction.month < fromYear * 100 + fromMonth,
            };

            if (!slicePrefix) {
                current.slice = sliceType.length > 0
                    ? sliceType.map(s => current[s]).join(' / ')
                    : 'Total';
            } else {
                current.slice = sliceType.length > 0
                    ? [slicePrefix, ...sliceType.map(s => current[s])].join(' / ')
                    : slicePrefix;
            }
            preparedData.push(current);
        })
    }

    return preparedData;
}

export const SalesPlanChart = forwardRef((props, ref) => {
    const [sliceType, setSliceType] = useState([]);
    const [dateRange, setDateRange] = useState([new Date('2020-01-01'), new Date('2022-12-31')]);

    const selectedRowKeys = useSelector(state => state.salesPlanGrid.selectedRowKeys);

    // Selected sales plan data
    const salesPlanId = useSelector((state) => state.menu.salesPlanId);
    const {data: currentSalesPlan} = useSalesPlanIdQuery(salesPlanId, {
        enabled: Boolean(salesPlanId),
        notifyOnChangeProps: ['data'],
    });

    // Compare sales plan data
    const comparePlanId = useSelector((state) => state.salesPlanGrid.comparePlanId);
    const {data: compareSalesPlan} = useSalesPlanIdQuery(comparePlanId, {
        enabled: Boolean(comparePlanId) && Boolean(salesPlanId),
        notifyOnChangeProps: ['data'],
    });

    const chartData = [];

    // Create handle to re-render component from parent (in case layout changes)
    const chartRef = useRef();
    const sliderRef = useRef();

    useImperativeHandle(ref, () => ({
        render: () => {
            if (chartRef.current.instance?.render !== undefined) {
                chartRef.current.instance?.render();
            }
            if (sliderRef.current.instance?.render !== undefined) {
                sliderRef.current.instance?.render();
            }
        }
    }));

    // Prepare data to plot
    if (currentSalesPlan && currentSalesPlan.sales_plan_entities !== undefined) {
        const slicePrefix = compareSalesPlan ? 'New' : undefined;
        const selectedRows = [];
        const keys = ['marketplace', 'region', 'product.id'];

        currentSalesPlan.sales_plan_entities
            // Filter only data selected in the SalesPlanGrid
            .filter(row => (selectedRowKeys.length === 0) || selectedRowKeys.includes(row.id))
            .forEach(row => {
                // Save rows keys to filter second sales plan
                selectedRows.push(row);
                // Flatten data
                chartData.push(
                    ...prepareDataRow(row, sliceType, currentSalesPlan.from_year, currentSalesPlan.from_month, slicePrefix)
                );
            });

        // If there is a sales plan for comparison...
        if (compareSalesPlan && compareSalesPlan.sales_plan_entities) {
            const compareRows = (rowA, rowB) => compare(
                keys.map(key => get(rowA, key)),
                keys.map(key => get(rowB, key))
            );

            compareSalesPlan.sales_plan_entities
                // TODO: find a way to compare sales plans if the previous plan has products that current plan doesn't
                // ...use only those rows, that were selected (if any row was selected)
                .filter(row =>
                    (selectedRowKeys.length === 0) || selectedRows.find(selectedRow => compareRows(selectedRow, row))
                )
                .forEach(row => chartData.push(
                    ...prepareDataRow(row, sliceType, compareSalesPlan.from_year, compareSalesPlan.from_month, 'Previous')
                ));
        }
    }

    // Tooltip config
    function makeTooltip(pointInfo) {
        return {
            text: [
                pointInfo.seriesName,
                formatDate(pointInfo.argument, 'yyyy MMM'),
                formatNumber(pointInfo.originalValue, 'fixedPoint'),
            ].join('\n')
        };
    }

    // Customize point depending on whether it is fact or plan
    function stylizePoint(pt) {
        const planPointStyle = {
            color: 'white',
            border: {
                color: pt.series.getColor(),
                width: 2,
            },
            hoverStyle: {
                color: 'white',
                border: {
                    color: pt.series.getColor()
                },
            }
        };

        if (pt.aggregationInfo?.data?.length > 0) {
            return pt.aggregationInfo.data[0].isFact ? {} : planPointStyle
        }
        return {}
    }

    return (
        <Fragment>
            <Chart
                ref={chartRef}
                title={'Sales chart'}
                id={'sales_chart'}
                style={{'marginBottom': '10px'}}
                dataSource={chartData.filter(row => dateRange[0] <= row.date && row.date <= dateRange[1])}
                animation={false}
                onLegendClick={({target}) => (
                    target.isVisible() ? target.hide() : target.show()
                )}
                customizePoint={stylizePoint}
            >
                <Tooltip
                    enabled={true}
                    customizeTooltip={makeTooltip}
                />
                <Legend
                    horizontalAlignment={'center'}
                    verticalAlignment={'bottom'}
                />
                <ArgumentAxis
                    argumentType={'datetime'}
                    aggregationInterval={'day'}
                    minorTick={{visible: true}}
                    minorTickInterval={'month'}
                    grid={{visible: true}}
                    minorGrid={{visible: true}}
                />
                <ValueAxis
                    showZero={true}
                    minorTick={{visible: true}}
                    minorGrid={{visible: true}}
                />
                <CommonSeriesSettings
                    argumentField={sliceType.includes('year') ? 'month' : 'date'}
                    valueField={'forecast'}
                    aggregation={{
                        enabled: true,
                        method: 'sum',
                    }}
                    point={{
                        size: 5,
                        border: {
                            visible: true,
                        }
                    }}
                    hoverStyle={{
                        size: 10,
                    }}
                />
                <SeriesTemplate nameField={'slice'}/>
            </Chart>
            <SliceSelector
                dataSource={[
                    {name: 'Year', value: 'year'},
                    {name: 'Marketplace', value: 'marketplace'},
                    {name: 'Region', value: 'region'},
                    {name: 'Product title', value: 'product_title'},
                    {name: 'Product ID', value: 'product_description'},
                ]}
                onValueChanged={({value}) => setSliceType((value))}
            />
            <DateSelector
                ref={sliderRef}
                dataSource={currentSalesPlan}
                onValueChanged={({value}) => setDateRange(value)}
            />
        </Fragment>
    )
});